import React from "react";

export default function CoursesHeader({data, cohortData, isMentor, completion, color}){
  return(
    <div>
      <div
        className={`py-1 bg-${color}-600 hover:bg-${color}-500 items-center text-${color}-100 leading-none rounded-full flex inline-flex mb-2 cursor-default`}
        role="alert"
      >
        <span className="flex rounded-full uppercase px-1 py-1 text-xs font-bold mr-2 ml-2">
          {cohortData.name}{" "} {cohortData.cohort}
          {isMentor ? " — TEAM" : null}
        </span>
      </div>
      <h1 className="text-3xl tracking-tight text-gray-900">
        Welcome,{" "}
        <span className={`font-bold text-gray-900`}>
          {" "}
          {data.username}{" "}
        </span>
      </h1>
      <div className="py-4">
        <div className="flex items-center justify-between">
          <div>
            <span className={`text-xs font-semibold inline-block uppercase text-gray-800 mb-1`}>
              Progress
            </span>
          </div>
          <div className="text-right">
            <span className={`text-xs font-semibold inline-block text-gray-800 ml-4`}>
              {completion.toFixed(0)}%
            </span>
          </div>
        </div>
        <div className={`overflow-hidden h-2 text-xs flex rounded bg-${color}-100`}>
          <div
            style={{ width: completion + "%" }}
            className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-${color}-600`}
          ></div>
        </div>
      </div>
    </div>
  );
}