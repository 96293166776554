import React, { useEffect, useState } from "react";
export default function ResourceLinks({ title, fields }) {
  const [category, setCategory] = useState([]);

  useEffect(() => {
    let array = Array.from(new Set(fields.map((item) => item.category)));
    setCategory(array);
  }, [fields]);

  return (
    <div className="flex mt-4">
      <div className="w-1/2">
        <h1 className="text-2xl tracking-tight text-gray-900 mb-2 mt-6 font-bold">
          {title} &#8250;
        </h1>
        <div>
          {category.map((cat) => (
            <div key={cat}>
              <h1 className="text-xl tracking-tight text-gray-900 mb-2 mt-6 font-bold">
                {cat} &#8250;
              </h1>
              {fields
                .filter((word) => word.category === cat)
                .map((item, index) => (
                  <a
                    key={index}
                    className="text-xl block hover:text-gray-800 text-gray-600 mb-1"
                    target="_blank"
                    href={item.link}
                  >
                    {item.name}
                  </a>
                ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
