import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql, navigate } from "gatsby";
import Helmet from "react-helmet";
import { Link } from "gatsby";

import {
  pushWebDevelopmentProjectCompletion,
  pushCourseWorkshopCompletion,
  getCourseCohortCompletions,
  getCohortMilestones,
  greaterThanXDays,
  fetchUserDataByUid,
  getLast5MakerLogPosts,
  getCourseCohortData,
  getCourseResources,
  getCourseCurrentCohort
} from "api/db";
import { auth } from "api/auth";
import { storage } from "api/firebase";
import CoursesHeader from "components/Cohort/Dashboard/CoursesHeader";
import ResourceLinks from "components/Cohort/Dashboard/ResourceLinks";

const WebDevelopmentCourse = () => {
  const enlightProjects = useStaticQuery(projects).allTutorial.nodes;
  const [userData, setUserData] = useState(); // userData
  const [webDevIndex, setWebDevIndex] = useState(null); // index of cohort
  const [progress, setProgress] = useState(0); // progress bar percentage
  const [milestones, setMilestones] = useState(null); // milestones & completion data
  const [mentor, setMentor] = useState(false); // isMentor?
  const [posts, setPosts] = useState([]); // maker log posts
  const [customProject, setCustomProject] = useState(false); // project3
  const [uploadState, setUploadState] = useState(false); // upload state
  const [cohortData, setCohortData] = useState([]);
  const [resources, setResources] = useState([]);
  const [cohortNum, setCohortNum] = useState(null);
  const courseName = "web-development";

  const [projectSubmission, setProjectSubmission] = useState({
    // project submission
    id: null,
    date: 0,
    demo: null,
    description: null,
    github: null,
    img: null,
    numericalId: null,
    postId: null,
    title: null,
    uid: null
  });

  const [workshopSubmission, setWorkshopSubmission] = useState({
    // workshop submission
    date: 0,
    demo: null,
    img: "",
    workshop: null,
    uid: null
  });

  useEffect(() => {
    let userData = JSON.parse(sessionStorage.getItem("userData"));
    setUserData(userData);

    if (!userData && auth && auth.currentUser && auth.currentUser.uid) {
      fetchUserDataByUid(auth.currentUser.uid).once("value", function(
        snapshot
      ) {
        setUserData(snapshot.val());
      });
    }
    let acceptedTags = ["team", "mentor"];
    if (
      (userData &&
        userData.courses &&
        userData.courses.find(s => s.includes("wd"))) ||
      (userData &&
        userData.tags &&
        acceptedTags.some(tag => userData.tags.includes(tag)))
    ) {
      if (
        userData.tags &&
        acceptedTags.some(tag => userData.tags.includes(tag))
      ) {
        setMentor(true);
        setProgress(100);
        getCourseCohortData(courseName).then(snapshot => {
          setCohortData(snapshot.val());
          setCohortNum(snapshot.val().currentCohort);
        });
      } else {
        let wd = userData.courses.filter(s => s.includes("wd"));
        let wdcurrent = wd[wd.length - 1];
        let cohortNumber = parseInt(wdcurrent.slice(wdcurrent.length - 1));
        setCohortNum(cohortNumber);
        getCourseCurrentCohort(courseName, cohortNumber).then(snapshot => {
          setCohortData(snapshot.val());
        });
      }
      setWebDevIndex(cohortNum);
    } else {
      navigate("/login");
    }
  }, [auth && auth.currentUser, cohortNum]);

  useEffect(() => {
    if (cohortNum != null) {
      getCohortMilestones(courseName, cohortNum).then(snapshot => {
        let milestoneData = Object.values(snapshot.val());
        milestoneData.sort((a, b) => a.datetime - b.datetime);
        setMilestones(milestoneData);

        getCourseCohortCompletions(
          courseName,
          cohortNum,
          auth.currentUser.uid,
          function(snapshot) {
            let data = snapshot.val();
            let updatedMilestoneData = milestoneData;

            if (
              data.milestone == "project-1" ||
              data.milestone == "project-2" ||
              data.milestone == "project-3"
            ) {
              let index = updatedMilestoneData.findIndex(
                x => x.id == data.milestone
              );
              updatedMilestoneData[index].completed = true;
              updatedMilestoneData[index].title = data.title;
              updatedMilestoneData[index].demo = data.demo;
              updatedMilestoneData[index].description = data.description;
              updatedMilestoneData[index].github = data.github;
              updatedMilestoneData[index].img = data.img;
              updatedMilestoneData[index].submittedDate = data.date;
            } else {
              let index = updatedMilestoneData.findIndex(
                x => x.id == data.milestone
              );
              updatedMilestoneData[index].completed = true;
              updatedMilestoneData[index].demo = data.demo;
              updatedMilestoneData[index].img = data.img;
              updatedMilestoneData[index].submittedDate = data.date;
            }
            setMilestones(updatedMilestoneData);

            let completedCount = 0;
            for (let i = 0; i < updatedMilestoneData.length; i++) {
              if (
                updatedMilestoneData[i] &&
                updatedMilestoneData[i].completed == true
              ) {
                completedCount++;
              }
            }

            setProgress(completedCount * (100 / 9));
          }
        );
      });
      getLast5MakerLogPosts(snapshot => {
        let data = snapshot.val();
        let key = snapshot.key;
        fetchUserDataByUid(data.uid).once("value", function(snapshot) {
          let userData = snapshot.val();
          let keyObject = { key: key };
          const object = { ...data, ...userData, ...keyObject };
          setPosts(users => users.concat(object));
        });
      });
      getCourseResources(courseName).then(snapshot => {
        setResources(snapshot.val());
      });
    }
  }, [cohortNum]);

  const displayDate = datetime => {
    var date = new Date(datetime);
    var now = new Date();
    var tomorrow = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + 1
    );
    let dateString = "";
    let hours = parseInt((date.getTime() - Date.now()) / (1000 * 60 * 60));
    let minutes = parseInt((date.getTime() - Date.now()) / (1000 * 60));
    let remainderMin =
      parseInt((date.getTime() - Date.now()) / (1000 * 60)) % 60;

    if (greaterThanXDays(Date.now(), date.getTime(), 1)) {
      if (
        date.getFullYear() == tomorrow.getFullYear() &&
        date.getMonth() == tomorrow.getMonth() &&
        date.getDate() == tomorrow.getDate()
      ) {
        dateString =
          "tomorrow" +
          ", " +
          date.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true
          });
      } else {
        dateString =
          date.getMonth() +
          1 +
          "/" +
          date.getDate() +
          ", " +
          date.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true
          });
      }
    } else if (minutes >= 5 && minutes < 60) {
      dateString = minutes + " minutes";
    } else if (hours == 1) {
      // one hour
      dateString = hours + " hour " + remainderMin + " min";
    } else if (hours >= 1) {
      // greater than 1 hour
      dateString =
        hours +
        " hours " +
        remainderMin +
        " min, " +
        date.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true
        });
    } else if (Date.now() > datetime + 7200000) {
      dateString = new Date(datetime).toLocaleString();
    } else {
      dateString = "live";
    }
    return dateString;
  };

  return (
    <>
      <Helmet title="Dashboard — Intro to Web Development | Enlight" />

      <div className="bg-white">
        <section className="mx-auto max-w-screen-xl sm:px-6 lg:px-8 mt-8">
          {userData ? (
            <div className="p-4 sm:px-2 lg:px-0">
              <CoursesHeader
                data={userData}
                cohortData={cohortData}
                isMentor={mentor}
                completion={progress}
                color="red"
              />
              <div className="py-4 mt-2">
                {!mentor && progress == 100 ? (
                  <div className="mb-8">
                    <a
                      href={`/courses/web-development/certificate?user=${userData.id}`}
                      target="_blank"
                      className="text-xl tracking-tight text-red-100 mb-4 p-2 rounded-lg bg-red-800 hover:opacity-75 cursor-pointer"
                    >
                      View Certificate &#8250;
                    </a>
                  </div>
                ) : (
                  <h1 className="text-2xl tracking-tight text-gray-900 mb-4 font-bold">
                    What's next &#8250;
                  </h1>
                )}

                <section className="grid md:grid-cols-3 gap-4">
                  {milestones &&
                    milestones
                      .filter(a => !a.completed)
                      .map((milestone, index) => (
                        <div
                          className="py-4 bg-gray-100 rounded"
                          key={milestone.id}
                        >
                          <div className="p-4">
                            <h1 className="text-gray-700 text-sm uppercase">
                              {displayDate(milestone.datetime)}
                            </h1>
                            <h1 className="tracking-tight font-bold text-gray-900 text-xl">
                              {milestone.name}
                            </h1>

                            <h2 className="text-gray-700 text-sm">
                              {milestone.description}
                            </h2>

                            {milestone.type == "event" &&
                            milestone.submissionRequired ? (
                              <div className="py-2">
                                {Date.now() > milestone.datetime - 600000 ? (
                                  <div>
                                    {Date.now() >
                                    milestone.datetime + 7200000 ? ( // recording two hours after event start
                                      <Link
                                        to={"/courses/web-development/event"}
                                        target="_blank"
                                        state={{
                                          eventName: milestone.id,
                                          userData: userData,
                                          cohortNum: cohortNum
                                        }}
                                        className="tracking-tight font-bold text-blue-700 text-xl mb-3 hover:opacity-75"
                                      >
                                        View recording &#8250;
                                      </Link>
                                    ) : (
                                      <a
                                        target="_blank"
                                        href={`https://zoom.enlight.nyc`}
                                        className="tracking-tight font-bold text-blue-700 text-xl mb-3 hover:opacity-75"
                                      >
                                        Join Zoom &#8250;
                                      </a>
                                    )}{" "}
                                  </div>
                                ) : (
                                  <h1 className="tracking-tight font-bold text-gray-500 text-xl mb-3">
                                    Join &#8250;
                                  </h1>
                                )}

                                {Date.now() > milestone.datetime - 600000 &&
                                !mentor ? ( // (switch to test)
                                  <>
                                    {index == 0 ? (
                                      <>
                                        <label className="block tracking-tight text-gray-700 text-xs font-bold mb-2 mt-3">
                                          Demo (Repl.it or Netlify)
                                        </label>
                                        <input
                                          placeholder="https://"
                                          className="p-1 bg-gray-100 w-full"
                                          onChange={e => {
                                            setWorkshopSubmission({
                                              ...workshopSubmission,
                                              workshop: milestone.id,
                                              demo: e.target.value,
                                              date: Date.now(),
                                              uid: auth.currentUser.uid,
                                              title: milestone.name
                                            });
                                          }}
                                        />
                                        <input
                                          className="text-xs my-2"
                                          accept="image/*"
                                          onChange={e => {
                                            const image = e.target.files[0];
                                            let size = image.size / 1024 / 1024; // in MB
                                            if (size > 10) {
                                              alert("File size exceeds 10 MB");
                                            } else {
                                              const upload = storage
                                                .ref(
                                                  `/workshopSubmissions/${image.name}`
                                                )
                                                .put(image);

                                              upload.on(
                                                "state_changed",
                                                snapshot => {
                                                  setUploadState(true);
                                                },
                                                err => {
                                                  // console.log(err);
                                                },
                                                () => {
                                                  storage
                                                    .ref("workshopSubmissions")
                                                    .child(image.name)
                                                    .getDownloadURL()
                                                    .then(url => {
                                                      setUploadState(false);
                                                      setWorkshopSubmission({
                                                        ...workshopSubmission,
                                                        img: url
                                                      });
                                                    });
                                                }
                                              );
                                            }
                                          }}
                                          type="file"
                                        />
                                        {uploadState ? (
                                          <p>Uploading...</p>
                                        ) : null}
                                        <br />
                                        <button
                                          onClick={() => {
                                            if (
                                              workshopSubmission.uid &&
                                              workshopSubmission.workshop &&
                                              workshopSubmission.date &&
                                              workshopSubmission.demo
                                            ) {
                                              pushCourseWorkshopCompletion(
                                                webDevIndex,
                                                workshopSubmission.uid,
                                                workshopSubmission.workshop,
                                                workshopSubmission.date,
                                                workshopSubmission.demo,
                                                workshopSubmission.img,
                                                workshopSubmission.title,
                                                courseName
                                              );
                                              setWorkshopSubmission({
                                                date: 0,
                                                demo: "",
                                                img: "",
                                                workshop: "",
                                                uid: ""
                                              });
                                            } else {
                                              alert(
                                                "Please fill out all fields."
                                              );
                                            }
                                          }}
                                          className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-2 rounded-full text-sm mt-2"
                                        >
                                          submit
                                        </button>
                                      </>
                                    ) : null}
                                  </>
                                ) : null}
                              </div>
                            ) : (
                              <div>
                                {milestone.submissionRequired &&
                                index == 0 &&
                                !mentor ? (
                                  <div className="py-4">
                                    <select
                                      className="block appearance-none w-full bg-gray-100 border border-gray-200 text-gray-700 p-1 rounded leading-tight focus:outline-none focus:bg-gray-100 focus:border-gray-500"
                                      onChange={event => {
                                        if (milestone.id == "project-1") {
                                          setProjectSubmission({
                                            ...projectSubmission,
                                            title: "Build a Personal Website",
                                            numericalId: 28,
                                            postId: "build-a-personal-website",
                                            date: Date.now(),
                                            uid: auth.currentUser.uid,
                                            id: milestone.id
                                          });
                                        } else if (
                                          event.target.value == "custom"
                                        ) {
                                          setCustomProject(true);
                                          setProjectSubmission({
                                            ...projectSubmission,
                                            title: "Capstone Project",
                                            numericalId: 0,
                                            postId: 0,
                                            date: Date.now(),
                                            uid: auth.currentUser.uid,
                                            id: milestone.id
                                          });
                                        } else {
                                          setProjectSubmission({
                                            ...projectSubmission,
                                            title:
                                              enlightProjects[
                                                event.target.value
                                              ].title,
                                            numericalId:
                                              enlightProjects[
                                                event.target.value
                                              ].projectId,
                                            postId:
                                              enlightProjects[
                                                event.target.value
                                              ].id,
                                            date: Date.now(),
                                            uid: auth.currentUser.uid,
                                            id: milestone.id
                                          });
                                        }
                                      }}
                                      name="Projects"
                                    >
                                      {milestone.id == "project-1" ? (
                                        <>
                                          <option selected disabled>
                                            Select project...
                                          </option>
                                          <option value="2">
                                            Build a Personal Website (HTML/CSS)
                                          </option>
                                        </>
                                      ) : (
                                        <>
                                          {milestone.id == "project-3" ? (
                                            <>
                                              <option selected disabled>
                                                Select project...
                                              </option>
                                              <option value="custom">
                                                Custom project
                                              </option>
                                              {enlightProjects &&
                                                enlightProjects.map(
                                                  (project, index) => (
                                                    <option
                                                      key={project.id}
                                                      value={index}
                                                    >
                                                      {project.title} (
                                                      {project.language})
                                                    </option>
                                                  )
                                                )}
                                            </>
                                          ) : (
                                            <>
                                              <option selected disabled>
                                                Select project...
                                              </option>
                                              {enlightProjects &&
                                                enlightProjects.map(
                                                  (project, index) => (
                                                    <option
                                                      key={project.id}
                                                      value={index}
                                                    >
                                                      {project.title} (
                                                      {project.language})
                                                    </option>
                                                  )
                                                )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </select>

                                    <label className="block tracking-tight text-gray-700 text-xs font-bold mb-2 mt-3">
                                      Description
                                    </label>
                                    <input
                                      placeholder="I built ... I learned..."
                                      className="p-1 bg-gray-100 w-full"
                                      onChange={event => {
                                        setProjectSubmission({
                                          ...projectSubmission,
                                          description: event.target.value
                                        });
                                      }}
                                    />

                                    <label className="block tracking-tight text-gray-700 text-xs font-bold mb-2 mt-3">
                                      Demo (Repl.it or Netlify)
                                    </label>
                                    <input
                                      placeholder="https://"
                                      className="p-1 bg-gray-100 w-full"
                                      onChange={event => {
                                        setProjectSubmission({
                                          ...projectSubmission,
                                          demo: event.target.value
                                        });
                                      }}
                                    />
                                    <label className="block tracking-tight text-gray-700 text-xs font-bold mb-2 mt-3">
                                      Source (GitHub)
                                    </label>

                                    <input
                                      placeholder="https://"
                                      className="p-1 bg-gray-100 w-full"
                                      onChange={event => {
                                        setProjectSubmission({
                                          ...projectSubmission,
                                          github: event.target.value
                                        });
                                      }}
                                    />
                                    <label className="block tracking-tight text-gray-700 text-xs font-bold mt-3">
                                      Image (Screenshot)
                                    </label>
                                    <input
                                      className="text-xs my-2"
                                      accept="image/*"
                                      onChange={e => {
                                        const image = e.target.files[0];
                                        let size = image.size / 1024 / 1024; // in MB
                                        if (size > 10) {
                                          alert("File size exceeds 10 MB");
                                        } else {
                                          const upload = storage
                                            .ref(
                                              `/projectSubmissions/${image.name}`
                                            )
                                            .put(image);

                                          upload.on(
                                            "state_changed",
                                            snapshot => {
                                              // console.log(snapshot);
                                            },
                                            err => {
                                              // console.log(err);
                                            },
                                            () => {
                                              storage
                                                .ref("projectSubmissions")
                                                .child(image.name)
                                                .getDownloadURL()
                                                .then(url => {
                                                  setProjectSubmission({
                                                    ...projectSubmission,
                                                    img: url
                                                  });
                                                });
                                            }
                                          );
                                        }
                                      }}
                                      type="file"
                                    />
                                    <br />
                                    <button
                                      onClick={() => {
                                        if (
                                          projectSubmission.uid != null &&
                                          projectSubmission.id != null &&
                                          projectSubmission.date != null &&
                                          projectSubmission.demo != null &&
                                          projectSubmission.description !=
                                            null &&
                                          projectSubmission.github != null &&
                                          projectSubmission.img != null &&
                                          projectSubmission.numericalId !=
                                            null &&
                                          projectSubmission.postId != null &&
                                          projectSubmission.title != null
                                        ) {
                                          pushWebDevelopmentProjectCompletion(
                                            webDevIndex,
                                            projectSubmission.uid,
                                            projectSubmission.id,
                                            projectSubmission.date,
                                            projectSubmission.demo,
                                            projectSubmission.description,
                                            projectSubmission.github,
                                            projectSubmission.img,
                                            projectSubmission.numericalId,
                                            projectSubmission.postId,
                                            projectSubmission.title,
                                            customProject
                                          );
                                          setProjectSubmission({
                                            id: null,
                                            date: 0,
                                            demo: "",
                                            description: "",
                                            github: "",
                                            img: "",
                                            numericalId: null,
                                            postId: null,
                                            title: null,
                                            uid: ""
                                          });
                                        } else {
                                          alert(
                                            "Please fill out all fields, including uploading an image."
                                          );
                                        }
                                      }}
                                      className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-2 rounded-full text-sm mt-2"
                                    >
                                      submit
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                </section>

                <h1 className="text-2xl tracking-tight text-gray-900 mb-4 mt-6 font-bold">
                  Completed &#8250;
                </h1>

                <section className="grid md:grid-cols-3 gap-4">
                  {milestones &&
                    milestones
                      .filter(a => a.completed == true)
                      .map(milestone => (
                        <div
                          className="py-4 bg-gray-100 rounded"
                          key={milestone.id + "completed"}
                        >
                          <div className="p-4">
                            <h1 className="tracking-tight font-bold text-gray-900 text-xl">
                              {milestone.name}
                            </h1>

                            {milestone.type == "event" ? (
                              <Link
                                to={"/courses/web-development/event"}
                                target="_blank"
                                state={{
                                  eventName: milestone.id,
                                  userData: userData,
                                  cohortNum: cohortNum
                                }}
                                className="tracking-tight font-bold text-blue-700 text-xl mb-3 hover:opacity-75"
                              >
                                View recording &#8250;
                              </Link>
                            ) : (
                              <h1 className="tracking-tight font-bold text-gray-900 text-xl">
                                {milestone.title}
                              </h1>
                            )}

                            <a
                              target="_blank"
                              href={milestone.demo}
                              className="text-blue-700 text-sm block font-bold hover:opacity-75"
                            >
                              View submission
                              {milestone.img ? (
                                <img
                                  className="h-16 rounded mt-2"
                                  src={milestone.img}
                                />
                              ) : null}
                              <span className="block text-xs text-gray-700 mt-2">
                                {" "}
                                {new Date(
                                  milestone.submittedDate
                                ).toLocaleString()}{" "}
                              </span>
                            </a>
                          </div>
                        </div>
                      ))}
                </section>
                <ResourceLinks title="Resources" fields={resources} />
              </div>
            </div>
          ) : (
            <></>
          )}
        </section>
      </div>
    </>
  );
};

export default WebDevelopmentCourse;

export const projects = graphql`
  query projects {
    allTutorial(filter: { published: { eq: true } }) {
      nodes {
        date
        description
        id
        img
        difficulty
        title
        published
        language
        projectId
      }
    }
  }
`;
